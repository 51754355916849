import { useTranslation } from 'react-i18next';
import { OfferState, OfferStatus as OfferStatusState } from 'pages/find-loads-ver2/hooks/use-carrier-offer.hook';
import { OfferIcon } from 'pages/find-loads-ver2/offers/offer-icon';
import { Currency } from 'shared/components/formatters/currency.formatter';
import { OfferExpirationTimer } from 'pages/find-loads-ver2/offers/offer-expiration-timer.component';

interface Props {
  offerState: OfferState;
  showTimer?: boolean;
}

function calculateOfferStatusResource(offerSatus: OfferStatusState) {
  if (offerSatus?.isOfferExpired || offerSatus?.isOfferIgnored) {
    return 'OFFER_EXPIRED';
  }
  if (offerSatus?.isOfferCountered) {
    return 'OFFER_COUNTERED_LABEL';
  }
  if (offerSatus?.isOfferAccepted) {
    return 'ACCEPTED_OFFER';
  }
  if (offerSatus?.isOfferRejected) {
    return 'OFFER_REJECTED';
  }
  if (offerSatus?.isOfferOpen) {
    return 'OFFER_PENDING';
  }
  if (offerSatus?.isOfferClosed) {
    return 'OFFER_CLOSED';
  }

  return null;
}

export const OfferStatus = ({ offerState, showTimer }: Props) => {
  const { t } = useTranslation();
  const offerStatus = offerState?.offerStatus;
  const offer = offerState?.carrierOffer?.latestOffer;
  const offerStatusResource = calculateOfferStatusResource(offerStatus);
  const isAvailableAcceptedOffer = offerStatus?.isOfferAccepted && !offerStatus?.isOfferExpired;
  const isAvailableCounterOffer = offerStatus?.isOfferCountered && !offerStatus?.isOfferExpired;
  const isAcceptedOrCountered = isAvailableAcceptedOffer || isAvailableCounterOffer;

  if (!offerStatusResource || !offer) {
    return (<></>);
  }

  return (
    <div className="offer-status">
      {(!isAcceptedOrCountered) &&
        <span className="offer-amount" data-testid="offer-amount">
          <Currency value={offer.price} currency={offer.currencyCode} />&nbsp;
        </span>
      }
      <span className={isAcceptedOrCountered ? 'offer-status-accepted' : 'offer-status-label'} data-testid="offer-label">
        {isAcceptedOrCountered && <OfferIcon offerCountered={isAvailableCounterOffer} />}
        {t(offerStatusResource)}
      </span>
      {(isAcceptedOrCountered && showTimer) &&
        <span className="expiration-message" data-testid="offer-timer">
          <OfferExpirationTimer offerState={offerState} />
        </span>
      }
    </div>
  );
};
